import Header from "../../Components/Header";
import CaseStudyBanner from "../../Components/CaseStudyBanner";
import OverviewSection from '../../Components/OverviewSection';
import hometownHeroesHero from '../../Images/Hometown Heroes/hometown_heroes_hero.png';
import * as hometownHeroesStrings from '../../Strings/HometownHeroesStrings';
import '../CaseStudy.css';
import ContentSection from "../../Components/ContentSection";
import Footer from "../../Components/Footer";
import hometownHeroesPrioritization from '../../Images/Hometown Heroes/hometown_heroes_prioritization.png';
import hometownHeroesMentorshipUserFlow from '../../Images/Hometown Heroes/hometown_heroes_mentorship_user_flow.png';
import hometownHeroesConceptTesting from '../../Images/Hometown Heroes/hometown_heroes_concept_testing.png';
import hometownHeroesGigFinderUserFlow from '../../Images/Hometown Heroes/hometown_heroes_gig_finder_user_flow.png';
import hometownHeroesDesignSystem from '../../Images/Hometown Heroes/hometown_heroes_design_system.png';
import hometownHeroesCommunityOverview from '../../Videos/Hometown Heroes/hometown_heroes_community_overview.mp4';
import hometownHeroesMentorship from '../../Videos/Hometown Heroes/hometown_heroes_mentorship.mp4';
import hometownHeroesGigFinder from '../../Videos/Hometown Heroes/hometown_heroes_gig_finder.mp4';
import hometownHeroesTrackingAndManagement from '../../Videos/Hometown Heroes/hometown_heroes_tracking_and_management.mp4';
import SectionHeader from "../../Components/SectionHeader";
import Metric from "../../Components/Metric";
import TextEqualWidth from "../../Components/TextEqualWidth";
import BulletPoint from "../../Components/BulletPoint";
import Picture from "../../Components/Picture";
import VideoComponent from "../../Components/VideoComponent";
import ScrollToTop from '../../Components/ScrollToTop';
import Button from '../../Components/Button';
import { useRef } from 'react';


function HometownHeroes() {

  const finalDesignRef = useRef(null);

  const hometownHeroesOverviewItems = [
    {
      'title': hometownHeroesStrings.HOMETOWN_HEROES_OVERVIEW_TITLE1,
      'subtitle': hometownHeroesStrings.HOMETOWN_HEROES_OVERVIEW_SUBTITLE1
    },
    {
      'title': hometownHeroesStrings.HOMETOWN_HEROES_OVERVIEW_TITLE2,
      'subtitle': hometownHeroesStrings.HOMETOWN_HEROES_OVERVIEW_SUBTITLE2
    },
    {
      'title': hometownHeroesStrings.HOMETOWN_HEROES_OVERVIEW_TITLE3,
      'subtitle': hometownHeroesStrings.HOMETOWN_HEROES_OVERVIEW_SUBTITLE3
    },
    {
      'title': hometownHeroesStrings.HOMETOWN_HEROES_OVERVIEW_TITLE4,
      'subtitle': hometownHeroesStrings.HOMETOWN_HEROES_OVERVIEW_SUBTITLE4
    }
  ];






  return (
    <div>
      <Header />

      <div className="page-container">
        <div className="sections-container">

          <div className='container my-0 mx-auto hero-container'>
            <CaseStudyBanner
              title={hometownHeroesStrings.HOMETOWN_HEROES_BANNER_TITLE}
              subtitle={hometownHeroesStrings.HOMETOWN_HEROES_BANNER_SUBTITLE}
              image={hometownHeroesHero}
              alt_text={hometownHeroesStrings.HOMETOWN_HEROES_BANNER_ALT}
            />
            <OverviewSection
              item_list={hometownHeroesOverviewItems}
            />


          </div>


          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_OVERVIEW_TITLE}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">
              <p className="body grey">{hometownHeroesStrings.HOMETOWN_HEROES_SECTION_OVERVIEW_CONTENT}</p>
              <div className="w-full flex flex-col gap-4 md:flex-row md:gap-16">
                <Metric
                  emoji={'🎸'}
                  number={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_OVERVIEW_METRIC_NUMBER1}
                  label={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_OVERVIEW_METRIC_LABEL1}
                />
                <Metric
                  emoji={'🎧'}
                  number={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_OVERVIEW_METRIC_NUMBER2}
                  label={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_OVERVIEW_METRIC_LABEL2}
                />
              </div>

            </div>
          </div>


          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_CONTRIBUTIONS_TITLE}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">


              <BulletPoint
                number={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_CONTRIBUTIONS_BULLET_NUMBER1}
                subtitle={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_CONTRIBUTIONS_BULLET_SUBTITLE1}
              />

              <BulletPoint
                number={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_CONTRIBUTIONS_BULLET_NUMBER2}
                subtitle={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_CONTRIBUTIONS_BULLET_SUBTITLE2}
              />
              <BulletPoint
                number={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_CONTRIBUTIONS_BULLET_NUMBER3}
                subtitle={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_CONTRIBUTIONS_BULLET_SUBTITLE3}
              />


            </div>
          </div>


          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_CHALLENGE_TITLE}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-16">


              <BulletPoint
                title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_CHALLENGE_METRIC_NUMBER}
                subtitle={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_CHALLENGE_METRIC_LABEL}
              />


            </div>
          </div>

          <div className="container w-full flex flex-col gap-4 md:gap-16">
            <div className="w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
              <div className="w-full md:w-4/12">
                <SectionHeader
                  title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_SOLUTION_TITLE}
                  type='short'
                />
              </div>
              <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-16">


                <BulletPoint
                  title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_SOLUTION_METRIC_NUMBER}
                  subtitle={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_SOLUTION_METRIC_LABEL}
                />
              </div>
            </div>
            <div className="w-full px-4 md:px-16">
              <VideoComponent
                video={hometownHeroesCommunityOverview}
              />
            </div>
          </div>



          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_IMPACT_TITLE}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">


              <Metric
                emoji={'😀'}
                number={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_IMPACT_METRIC_NUMBER}
                label={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_IMPACT_METRIC_LABEL}
              />
              <p className="body grey">{hometownHeroesStrings.HOMETOWN_HEROES_SECTION_IMPACT_CONTENT}</p>


            </div>
          </div>




<hr className="divider" />

          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_PROCESS_TITLE}
                type='wide'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-16">
              <div className='pt-4 md:pt-0 md:text-end'>
                <Button
                  label={'Skip to final design'}
                  click_function={() => finalDesignRef.current?.scrollIntoView({ behavior: 'smooth' })}
                />
              </div>
            </div>
          </div>


          

          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_RESEARCH_TITLE}
                label={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_RESEARCH_LABEL}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-16">
              <div className="w-full flex flex-col gap-4 md:flex-row md:gap-16">
                <Metric
                  number={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_RESEARCH_METRIC_NUMBER1}
                  label={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_RESEARCH_METRIC_LABEL1}
                />
                <Metric
                  number={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_RESEARCH_METRIC_NUMBER2}
                  label={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_RESEARCH_METRIC_LABEL2}
                />
                <Metric
                  number={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_RESEARCH_METRIC_NUMBER3}
                  label={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_RESEARCH_METRIC_LABEL3}
                />
              </div>
              <div className="w-full flex flex-col gap-4 md:gap-8">
                <BulletPoint
                  title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_RESEARCH_FINDINGS_TITLE1}
                  subtitle={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_RESEARCH_FINDINGS_SUBTITLE1}
                />
                <BulletPoint
                  title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_RESEARCH_FINDINGS_TITLE2}
                  subtitle={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_RESEARCH_FINDINGS_SUBTITLE2}
                />
                <BulletPoint
                  title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_RESEARCH_FINDINGS_TITLE3}
                  subtitle={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_RESEARCH_FINDINGS_SUBTITLE3}
                />
              </div>
            </div>
          </div>


          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_PRIORITIZATION_TITLE}
                label={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_PRIORITIZATION_LABEL}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-16">
              <Picture
                image={hometownHeroesPrioritization}
                alt_text={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_PRIORITIZATION_ALT}
                rounded={true}
              />
            </div>
          </div>

          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_USER_FLOWS_TITLE}
                label={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_USER_FLOWS_LABEL}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-16">
              <Picture
                image={hometownHeroesMentorshipUserFlow}
                alt_text={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_USER_FLOWS_ALT}
                rounded={true}
              />
            </div>
          </div>

          <div className="container w-full flex flex-col gap-4 md:gap-16">
            <div className="w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
              <div className="w-full md:w-4/12">
                <SectionHeader
                  title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_CONCEPT_TESTING_TITLE}
                  label={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_CONCEPT_TESTING_LABEL}
                  type='short'
                />
              </div>
              <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">
                <p className="body">{hometownHeroesStrings.HOMETOWN_HEROES_SECTION_CONCEPT_TESTING_CONTENT}</p>
                <div className="w-full flex flex-col gap-4 md:gap-8">
                  <BulletPoint
                    title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_CONCEPT_TESTING_FINDINGS_TITLE1}
                    subtitle={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_CONCEPT_TESTING_FINDINGS_SUBTITLE1}
                  />
                  <BulletPoint
                    title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_CONCEPT_TESTING_FINDINGS_TITLE2}
                    subtitle={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_CONCEPT_TESTING_FINDINGS_SUBTITLE2}
                  />

                  <BulletPoint
                    title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_CONCEPT_TESTING_FINDINGS_TITLE3}
                    subtitle={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_CONCEPT_TESTING_FINDINGS_SUBTITLE3}
                  />
                </div>
              </div>
            </div>

            <div className="w-full px-4 md:px-16">
              <Picture
                image={hometownHeroesConceptTesting}
                alt_text={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_CONCEPT_TESTING_ALT}
              />
            </div>
          </div>

          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_PRODUCT_STRATEGY_TITLE}
                label={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_PRODUCT_STRATEGY_LABEL}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-16">
              <div className="w-full flex flex-col md:flex-row gap-4 md:gap-16">
                <BulletPoint
                  title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_PRODUCT_STRATEGY_CONTENT_TITLE1}
                  subtitle={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_PRODUCT_STRATEGY_CONTENT_SUBTITLE1}
                />
                <BulletPoint
                  title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_PRODUCT_STRATEGY_CONTENT_TITLE2}
                  subtitle={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_PRODUCT_STRATEGY_CONTENT_SUBTITLE2}
                />


              </div>

            </div>
          </div>

          <div className="w-full px-4 md:px-16 py-16 md:py-24 background-dark">
          <div className='container my-0 mx-auto px-4 md:px-16'>
            <SectionHeader
              title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_CONSTRAINT_TITLE}
              label={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_CONSTRAINT_LABEL}
              type='short'
              invert={true}
            />
          </div>
          </div>


          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_RESOLUTION_TITLE}
                label={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_RESOLUTION_LABEL}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-16">
              <Picture
                image={hometownHeroesGigFinderUserFlow}
                alt_text={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_RESOLUTION_ALT}
                rounded={true}
              />
            </div>
          </div>

          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_DESIGN_SYSTEM_TITLE}
                label={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_DESIGN_SYSTEM_LABEL}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-16">
              <Picture
                image={hometownHeroesDesignSystem}
                alt_text={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_DESIGN_SYSTEM_ALT}
                rounded={true}
              />
            </div>
          </div>



          <div className="container w-full flex flex-col gap-4 md:gap-16" ref={finalDesignRef}>
            <div className="w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
              <div className="w-full md:w-4/12">
                <SectionHeader
                  title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_FINAL_DESIGN_TITLE}
                  type='short'
                />
              </div>
            </div>




            <div className="w-full flex flex-col px-4 md:px-16 gap-8 md:gap-24">
              <div className="w-full flex flex-col gap-4 md:gap-8">
                <div className="w-full">
                  <h4 className="h4 dark">{hometownHeroesStrings.HOMETOWN_HEROES_SECTION_FINAL_DESIGN_MENTORSHIP_TITLE}</h4>
                  <h6 className="h6 dark">{hometownHeroesStrings.HOMETOWN_HEROES_SECTION_FINAL_DESIGN_MENTORSHIP_SUBTITLE}</h6>
                </div>
                <div className="w-full">
                  <VideoComponent
                    video={hometownHeroesMentorship}
                  />
                </div>
              </div>

              <div className="w-full flex flex-col gap-4 md:gap-8">
                <div className="w-full">
                  <h4 className="h4 dark">{hometownHeroesStrings.HOMETOWN_HEROES_SECTION_FINAL_DESIGN_GIG_FINDER_TITLE}</h4>
                  <h6 className="h6 dark">{hometownHeroesStrings.HOMETOWN_HEROES_SECTION_FINAL_DESIGN_GIG_FINDER_SUBTITLE}</h6>
                </div>
                <div className="w-full">
                  <VideoComponent
                    video={hometownHeroesGigFinder}
                  />
                </div>
              </div>

              <div className="w-full flex flex-col gap-4 md:gap-8">
                <div className="w-full">
                  <h4 className="h4 dark">{hometownHeroesStrings.HOMETOWN_HEROES_SECTION_FINAL_DESIGN_TRACKING_AND_MANAGEMENT_TITLE}</h4>
                  <h6 className="h6 dark">{hometownHeroesStrings.HOMETOWN_HEROES_SECTION_FINAL_DESIGN_TRACKING_AND_MANAGEMENT_SUBTITLE}</h6>
                </div>
                <div className="w-full">
                  <VideoComponent
                    video={hometownHeroesTrackingAndManagement}
                  />
                </div>
              </div>
            </div>
          </div>






















          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_FUTURE_SCOPE_TITLE}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-16">
              <div className="w-full flex flex-col gap-4 md:gap-8">
                <BulletPoint
                  number='01'
                  title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_FUTURE_SCOPE_TITLE1}
                  subtitle={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_FUTURE_SCOPE_SUBTITLE1}
                />
                <BulletPoint
                  number='02'
                  title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_FUTURE_SCOPE_TITLE2}
                  subtitle={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_FUTURE_SCOPE_SUBTITLE2}
                />
                <BulletPoint
                  number='03'
                  title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_FUTURE_SCOPE_TITLE3}
                  subtitle={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_FUTURE_SCOPE_SUBTITLE3}
                />

              </div>

            </div>
          </div>



          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_LEARNINGS_TITLE}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-16">
              <div className="w-full flex flex-col gap-4 md:gap-8">
                <BulletPoint
                  number={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_LEARNINGS_NUMBER1}
                  title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_LEARNINGS_TITLE1}
                  subtitle={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_LEARNINGS_SUBTITLE1}
                />
                <BulletPoint
                  number={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_LEARNINGS_NUMBER2}
                  title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_LEARNINGS_TITLE2}
                  subtitle={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_LEARNINGS_SUBTITLE2}
                />
                <BulletPoint
                  number={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_LEARNINGS_NUMBER3}
                  title={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_LEARNINGS_TITLE3}
                  subtitle={hometownHeroesStrings.HOMETOWN_HEROES_SECTION_LEARNINGS_SUBTITLE3}
                />


              </div>

            </div>
          </div>









        </div>

        <Footer />

      </div>

      <ScrollToTop />
    </div>
  );
}

export default HometownHeroes;
