
export const HOMEPAGE_NAME = 'Shaunak Bhanarkar';
export const HOMEPAGE_ROLE = 'Product designer crafting purposeful experiences with data-driven insights and systems thinking.';
export const HOMEPAGE_STATUS1 = '✨ Actively seeking full time opportunities';
export const HOMEPAGE_STATUS2 = '🚀 Open to relocation';

export const FEATURED_SECTION_HEADING = 'Featured work';
export const COMPETITION_SECTION_HEADING = 'Accolades';

export const PROJECT_ROCKET_TITLE = 'Rocket Mortgage Tasks';
export const PROJECT_ROCKET_SUBTITLE = 'Helping users focus on the right information to avoid delaying their mortgage closing date';
export const PROJECT_ROCKET_TAG1 = 'Fintech';
export const PROJECT_ROCKET_TAG2 = 'Product design';
export const PROJECT_ROCKET_TAG3 = 'Redesign';
export const PROJECT_ROCKET_ALT = 'An illustration of the redesigned Rocket Mortgage To-do Page';

export const PROJECT_CRUDE_TITLE = 'Crude Design System — 600+ users';
export const PROJECT_CRUDE_SUBTITLE = 'Creating a general purpose neubrutal design system for web platforms';
export const PROJECT_CRUDE_TAG1 = 'Design system';
export const PROJECT_CRUDE_TAG2 = 'UI design';
export const PROJECT_CRUDE_ALT = 'An illustration depicting color palette, typography, components, and accessibility sheet of Crude design system';

export const PROJECT_OYO_TITLE = 'Rate Plans — boosted CxR by 20%';
export const PROJECT_OYO_SUBTITLE = 'Introducing flexible booking options on the leading hotel booking app in India';
export const PROJECT_OYO_TAG1 = 'Hospitality';
export const PROJECT_OYO_TAG2 = 'Product design';
export const PROJECT_OYO_TAG3 = 'New feature';
export const PROJECT_OYO_ALT = 'An illustration of the OYO app depicting carousel of room category widget';

export const PROJECT_HOMETOWN_HEROES_COMMUNITY_TITLE = 'Hometown Heroes Community — over 90% user satisfaction rate';
export const PROJECT_HOMETOWN_HEROES_COMMUNITY_SUBTITLE = 'Conceptualizing and designing a community platform to facilitate the career growth of 3000+ local musicians';
export const PROJECT_HOMETOWN_HEROES_COMMUNITY_TAG1 = 'SaaS';
export const PROJECT_HOMETOWN_HEROES_COMMUNITY_TAG2 = 'Product design';
export const PROJECT_HOMETOWN_HEROES_COMMUNITY_TAG3 = '0 → 1';
export const PROJECT_HOMETOWN_HEROES_COMMUNITY_ALT = 'An illustration of the home page of Hometown Heroes Community';

export const PROJECT_HOMETOWN_HEROES_DESIGN_SYSTEM_TITLE = 'Hometown Heroes Design System ↗';
export const PROJECT_HOMETOWN_HEROES_DESIGN_SYSTEM_SUBTITLE = 'Creating a design system for a SaaS web platform';
export const PROJECT_HOMETOWN_HEROES_DESIGN_SYSTEM_TAG1 = 'SaaS';
export const PROJECT_HOMETOWN_HEROES_DESIGN_SYSTEM_TAG2 = 'UI design';
export const PROJECT_HOMETOWN_HEROES_DESIGN_SYSTEM_ALT = 'An illustration depicting Hometown Heroes Design System';

export const PROJECT_COMFORT_OS_TITLE = 'Comfort OS ↗';
export const PROJECT_COMFORT_OS_SUBTITLE = 'Designing system level interface and brand identity for a smart home device operating system';
export const PROJECT_COMFORT_OS_TAG1 = 'Design system';
export const PROJECT_COMFORT_OS_TAG2 = 'UI & brand design';
export const PROJECT_COMFORT_OS_ALT = 'An illustration of Comfort OS on a Nest Hub';


export const COMPETITION_USABILATHON_TITLE = 'Usabilathon 2022 ↗';
export const COMPETITION_USABILATHON_SUBTITLE = '1st position winner 🏆';
export const COMPETITION_USABILATHON_TAG1 = 'Meal kit';
export const COMPETITION_USABILATHON_ALT = 'An illustration of the redesigned Hello Fresh app';

export const COMPETITION_USABILATHON2024_TITLE = 'Usabilathon 2024 ↗';
export const COMPETITION_USABILATHON2024_SUBTITLE = '1st position winner 🏆';
export const COMPETITION_USABILATHON2024_TAG1 = 'Transport';
export const COMPETITION_USABILATHON2024_ALT = 'An illustration of the redesigned GoToll app';


export const COMPETITION_INFO_CHALLENGE_TITLE = 'Info Challenge 2023 ↗';
export const COMPETITION_INFO_CHALLENGE_SUBTITLE = '1st position winner 🏆';
export const COMPETITION_INFO_CHALLENGE_TAG1 = 'Data visualization';
export const COMPETITION_INFO_CHALLENGE_ALT = 'An illustration of the redesigned Pygmalion tool';

export const COMPETITION_PROTOTHON_TITLE = 'Protothon 2023 ↗';
export const COMPETITION_PROTOTHON_SUBTITLE = '2nd position winner 🎉';
export const COMPETITION_PROTOTHON_TAG1 = 'Femtech';
export const COMPETITION_PROTOTHON_TAG2 = 'AI';
export const COMPETITION_PROTOTHON_ALT = 'An illustration of a concept app for new mothers';

export const COMPETITION_MAKEATHON_TITLE = 'Makeathon 2023 ↗';
export const COMPETITION_MAKEATHON_SUBTITLE = '3rd position winner 🎊';
export const COMPETITION_MAKEATHON_TAG1 = 'Mental wellness';
export const COMPETITION_MAKEATHON_TAG2 = 'AI';
export const COMPETITION_MAKEATHON_ALT = 'An illustration of a concept mental health app for young adults';
